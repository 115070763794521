import Vue from 'vue'
import Vuex from 'vuex'

function updateCart(prefix,cart){
  localStorage.setItem(prefix,JSON.stringify(cart))
  if (cart.length===0)
  {
    window.location.href = '/app'; //link
  }
}

Vue.use(Vuex);
function getToken() {}
export default new Vuex.Store({
  state: {
    appName:'khodeman',
    baseUrl: 'http://g.samanyaar.ir/soccerp/',    //'http://localhost/h7withamlak/api',  //link
    baseUrlproblem:'http://g.samanyaar.ir/soccerp/',//'http://localhost/goverment/api',  //link
    siteUrl:'http://g.samanyaar.ir/soccerp/',
    prefix:'XBGfXhhXGPHIS_',
    auth:{
      loggedIn:false,
    },
    toast:{
      show:false,
      text:'',
      color:'pink', //green,red,pink,blue,amber,orange,yellow,
      timeout:2000,
    },
    cart:[],

  },
  getters:{
    baseUrl:function (state) {
      return state.baseUrl;
    },
    baseUrlproblem:function (state) {
      return state.baseUrlproblem;
    },
    siteUrl:function (state) {
      return state.siteUrl;
    },
    appToken:function (state) {

      //console.log('storage token : '+state.auth.data.token);
      //return 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9sb2NhbGhvc3RcL2RpZ2lzaG9wMlwvYXBpXC9sb2dpbiIsImlhdCI6MTY0Njg4MzQ0NCwiZXhwIjoxNjQ5NDc1NDQ0LCJuYmYiOjE2NDY4ODM0NDQsImp0aSI6ImFQcEhyVnc4Smx5VEdTMU8iLCJzdWIiOjQxMiwicHJ2IjoiMjNiZDVjODk0OWY2MDBhZGIzOWU3MDFjNDAwODcyZGI3YTU5NzZmNyJ9.tZP_0JFkSWdn0amqKHW1xoAqA5LHWUwd9Xi0aXSYAA4';

      //let $auth = localStorage.getItem("auth");
      if(state.auth.loggedIn) {
        return state.auth.data.token;
      }
      else
        return 'eyJ0eXAiOiJK';
    },
    auth:function (state) {
      return state.auth;
    },
    option:function(state){
      return state.option;
    },
    toast:function(state){
      return state.toast;
    },
    cart:function(state){
      return state.cart;
    },
    prefix:function(state){
      return state.prefix;
    }

  },
  mutations: {
    setToken(state,payload){
      state.appToken = payload.token;
      state.auth = payload;
      localStorage.setItem("auth",JSON.stringify(payload));
    },
    checkToken(state){
      let $auth = localStorage.getItem("auth");
      if($auth){
        state.auth = JSON.parse($auth);
      }
    },
    logout(state){
      let obj = {loggedIn:false};
      localStorage.setItem("auth",JSON.stringify(obj));
      state.auth = obj;
    },
    toast(state,payload){
      for(let key in payload){
        state.toast[key] = payload[key];
      }
    },
    setCart(state,payload){
      if (payload) {
          state.cart = JSON.parse(payload);
      }
    },
    addCart(state,payload){
      //console.log(state.cart);return 0;
      let items = state.cart;
      if (items && items.length ) {
        if(payload.count > parseInt(payload.quantity)){
          state.toast = {
            show:true,
            text:'این تعداد موجود نیست',
            color:'green',
          };
        }
          let find = items.filter(function (i){
              return parseInt(i.stock_id) == parseInt(payload.stock_id);
          });
          if(!find.length){
            items.push(payload);
          }else{
              //find[0].count += 1;
          }
          state.cart = items;
      } else {
          state.cart.push(payload);
      }
      state.toast = {
        show:true,
        text:payload.title + ' به سبد اضافه شد ',
        color:'green',
      };
      updateCart(state.prefix+'cart',state.cart);
    },
    deleteFromCart(state,payload){
        state.cart.splice(payload,1);

        state.toast = {
          show:true,
          text:' محصول از سبد حذف شد  ',
          color:'green',
        };
        updateCart(state.prefix+'cart',state.cart);
    },
    /*افزودن تعداد*/
    checkSum(state)  {
      var sum = 0;
      state.cart.forEach((item) => {
          //item.customer_price* item.count;
        sum+=item.customer_price* item.count;

      })
      localStorage.setItem('sumCart',sum)
    },

    /*افزودن تعداد*/
    plusCart(state,key){
      if(Array.isArray(state.cart)){
        state.cart[key].count++;
        updateCart(state.prefix+'cart',state.cart);
      }
    },
    /*کم کردن تعداد*/
    minusCart(state,key){
      if(Array.isArray(state.cart)){
        if(state.cart[key].count > 1){
          state.cart[key].count--;
          updateCart(state.prefix+'cart',state.cart);
        }else{
          state.toast = {
            show:true,
            text:'تعداد نمیتواند کمتر از یک باشد  ',
            color:'orange',
          };
        }
      }
    },
  },
  actions: {
    toast:function (context,payload) {
      context.commit("toast",payload);
    },
    checkToken({ commit }) {
      commit('checkToken');
    }
  },
  modules: {
  }
})
